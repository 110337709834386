import React from 'react';
import { useTranslation } from 'react-i18next';

const CompaniesList = () => {
  const { t } = useTranslation();

  const list = [
    {title: 'Бай Элим', media: 'companies-sample-1.png', lead: 'управляющая компания холдинга, занимающаяся координацией деятельности компаний, стратегическими проектами.', description: 'Управляющая компания холдинга "Бай Элим" - современный стратегический руководитель и координатор деятельности группы компаний. Наша миссия - разработка и реализация ключевых стратегических проектов, обеспечивая эффективное взаимодействие и синергию между всеми структурными подразделениями компании. Мы работаем на передовых рыночных практиках, применяя инновационные подходы к управлению, чтобы обеспечить успех и процветание наших предприятий.'},
    {title: 'Бай Элим Agro', media: 'companies-sample-2.jpg', lead: 'компания, занимающаяся выращиванием сельскохозяйственных продуктов.', description: 'Революционный подход к сельскому хозяйству – это наше кредо. Мы считаем, что фермерство - это не просто производство, а искусство и наука. Мы работаем с фермерами, внедряем передовые агротехнологии, обучаем их новым методам работы с растениями и зеленым технологиям. Цель - поднять уровень сельского хозяйства и сделать фермеров более осознанными и эффективными. Наша миссия не ограничивается выращиванием продукции. Мы меняем сельское хозяйство и мир вокруг нас.'},
    {title: 'Бай Элим Company', media: 'companies-sample-3.jpg', lead: 'компания, специализирующаяся на переработке и производстве пищевых продуктов.', description: 'Мы специализируемся на переработке сельскохозяйственной продукции, создавая разнообразные пищевые товары. Наши заводы обеспечены автоматизированными производственными процессами, включая фасовку и упаковку, и оснащены всем необходимым для переработки фруктов и овощей, что оптимизирует процесс приема и хранения сырья. Сратегически выгодное расположение обеспечивает быструю логистику. Важно отметить, что наша продукция соответствует высочайшим стандартам качества и безопасности.'},
    {title: 'Бай Элим Distribution', media: 'companies-sample-4.jpg', lead: 'это компания, которая занимается дистрибуцией различных видов товаров по всей территорию страны и предоставляет услуги по распространению продукции.', description: 'Собственный гараж грузового и легкового автотранспорта обеспечивает оперативную доставку товаров по всей стране. Наш автопарк состоит из 32 видов автомобильного транспорта, включая 13 большегрузочных машин (до 18-ти тонн), 9 среднегрузовых (до 3-х тонн) и 10 легковых автомобилей. Развитая торговая команда и слаженная сеть дистрибуции по всем каналам продаж позволяет нам эффективно связывать производителей с конечными потребителями, обеспечивая оптимальные условия сбыта и распределения продукции. '},
    {title: 'Бай Элим Logistics', media: 'companies-sample-5.jpg', lead: 'это компания, специализирующаяся на международных перевозках товаров и обладающая собственной железнодорожной инфраструктурой, что позволяет ей организовывать перевозки товаров в любую точку мира.', description: 'Мы обладаем собственной железнодорожной инфраструктурой, позволяющей нам доставлять грузы в любую точку мира. Склады оснащены передовым оборудованием для хранения и перевозки пищевых продуктов, обеспечивая идеальные условия хранения в соответствии с мировыми стандартами безопасности. Мы гордимся эффективной логистической системой, которая обеспечивает нашим клиентам максимальную надежность и высокое качество услуг, соответствуя стандартам международных перевозок. '},
    {title: 'Бай Элим Innovation', media: 'companies-sample-6.jpg', lead: 'компания, специализирующаяся на обучении и обмене информацией с аграрным сообществом, а также внедрении единых стандартов и инновационных технологий в сельском производстве.', description: 'Наша компания нацелена на поддержку аграрного сообщества и на внедрение передовых стандартов и инновационных технологий в сельском хозяйстве. Мы активно взаимодействуем с фермерами, делимся знаниями и передовыми практиками, чтобы помочь им эффективно управлять своими земельными ресурсами и выращивать культуры, обеспечивая их прибыльность и устойчивость. Мы соблюдаем принципы корпоративной социальной ответственности  и заботы об окружающей среде.'},
  ]
  
  return (
    <div className='companies__list'>
      {list.map((company, index) => {
        return (
          <div key={company.media} className='companies__item'>
            <div className='companies__item__media' style={{background: `url(/img/home/companies/${company.media}) no-repeat 50% 50% / cover`}} />
            <div className='companies__item__title'>
              {t(`companies.list.title${index+1}`)}
            </div>
            <div className='companies__item__lead inter-paragraph f-s-18'>
              {t(`companies.list.lead${index+1}`)}
            </div>
            <div className='companies__item__description inter-paragraph f-s-18'>
            {t(`companies.list.desc${index+1}`)}
            </div>
          </div>
        )
      })}
    </div>
  )
};

export default CompaniesList;